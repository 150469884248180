

@font-face {
    font-family: 'YsabeauSC';
    src: url('../font/YsabeauSC-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'YsabeauSC';
    src: url('../font/YsabeauSC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  